import React from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { useMediaQuery } from 'react-responsive'

const El = styled(({ show, ...rest }) => <animated.div {...rest} />)`
  position: fixed;
  transform: translateZ(0);
  z-index: 99;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: ${({ show }) => (show ? 1 : 0)};
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  text-transform: uppercase;
  display: none;
  color: ${({theme, white}) => white ? theme.white : theme.black};

  @media (hover: hover) {
    display: block;
  }
`

const Follow = ({ posX, posY, isPlaying, show, intro, drag }) => {
  const isHoverable = useMediaQuery({
    query: ' (hover: hover)',
  })

  const springProps = useSpring({
    transform: `translate(${posX + 20}px, ${posY + 20}px)`,
    config: { mass: 1, tension: 2000, friction: 100 },
  })

  return (
    <El show={show} white={drag} style={isHoverable ? { ...springProps } : {}}>
      {drag
        ? 'Drag'
        : intro
        ? 'Designers for tomorrow'
        : isPlaying
        ? 'pause'
        : 'play'}
    </El>
  )
}

export default Follow
