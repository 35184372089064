import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import W from '../styled/Wrapper'

const Wrapper = styled.div`
  position: relative;
`

const Vid = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;

  @media only screen and (min-width: 1024px) {
    height: ${({ fullHeight }) => (fullHeight ? '100vh' : 'auto')};
    width: 100%;
    object-fit: cover;
  }

  &[poster] {
    height: 100%;
    width: 100%;
    /* object-fit: fill; */

    @media only screen and (min-width: 1024px) {
      height: ${({ fullHeight }) => (fullHeight ? '100vh' : '100%')};
    }
  }
`

const TextWrapper = styled(W)`
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: ${({ timelineBelow }) => (timelineBelow ? '-45px' : '15px')};
  color: ${({ timelineBelow, theme }) =>
    timelineBelow ? theme.black : theme.white};
  display: ${({ timelineBelow }) => (timelineBelow ? 'flex' : 'block')};
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    display: block;
    bottom: ${({ narrow }) => (narrow ? '25px' : '40px')};
    padding-left: ${({ narrow }) => (narrow ? '20px' : '40px')};
    padding-right: ${({ narrow }) => (narrow ? '20px' : '40px')};
    color: ${({ theme }) => theme.white};
  }
`

const Button = styled.button`
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px;
  order: ${({ timelineBelow }) => (timelineBelow ? '2' : '0')};

  @media only screen and (min-width: 1024px) {
    color: ${({ theme }) => theme.white};
    font-size: 12px;
    padding: 18px;
  }
`

const ProgressWrapper = styled.div`
  height: 1px;
  width: 100%;

  background: ${({ timelineBelow }) =>
    timelineBelow ? ' rgba(0, 0, 0, 0.3)' : ' rgba(255, 255, 255, 0.3)'};

  @media only screen and (min-width: 1024px) {
    background: rgba(255, 255, 255, 0.3);
  }
`

const ProgressBar = styled.div`
  display: block;
  width: 0%;
  height: 100%;
  background-color: ${({ timelineBelow, theme }) =>
    timelineBelow ? theme.black : theme.white};
  transition: ${({ trans }) => (trans ? 'width 0.45s' : 'none')};

  @media only screen and (min-width: 1024px) {
    background-color: ${({ theme }) => theme.white};
  }
`

const Video = ({
  src,
  button,
  setShowVideo,
  setContext,
  sound,
  fullHeight,
  narrow,
  timelineBelow,
}) => {
  const [isMuted, setIsMuted] = useState(true)
  const [trans, setTrans] = useState(true)

  const progressBar = useRef(null)
  const vid = useRef(null)

  const handleTime = () => {
    if (!progressBar.current.getAttribute('data-max'))
      progressBar.current.setAttribute('data-max', vid.current.duration)

    const width = Math.round(
      (vid.current.currentTime / vid.current.duration) * 100
    )

    progressBar.current.style.width = width + '%'

    if ((width >= 99) | (width <= 1)) {
      setTrans(false)
    } else {
      setTrans(true)
    }

    if (vid.current.ended) {
      vid.current.currentTime = 0
    }
  }

  const setDurationVal = () => {
    progressBar.current.setAttribute(
      'data-max',
      Math.round(vid.current.duration)
    )
  }

  const handleButtonClick = e => {
    setShowVideo(true)
    setContext({ videoOpen: true })

    e.target.parentElement.parentElement.parentElement &&
      e.target.parentElement.parentElement.parentElement.querySelector(
        '.popup-video-play-pause'
      ) &&
      e.target.parentElement.parentElement.parentElement
        .querySelector('.popup-video-play-pause')
        .click()
  }

  useEffect(() => {
    if (!isMuted) {
      vid.current.muted = false
    } else {
      vid.current.muted = true
    }
  }, [isMuted])

  return (
    <Wrapper>
      <Vid
        muted
        loop
        autoPlay
        ref={vid}
        onTimeUpdate={handleTime}
        onLoadedMetadata={setDurationVal}
        fullHeight={fullHeight}
        playsInline
        poster={'../placeholder.png'}
      >
      {src && typeof src === 'string' ? (
        <source src={src}  />
      ) : (
        src.map((item, i) => {
          return <source src={item.url} type={item.mimeType} key={i} />
        })
      )}
      </Vid>
      <TextWrapper narrow={narrow} timelineBelow={timelineBelow}>
        {button && (
          <Button onClick={handleButtonClick} timelineBelow={timelineBelow}>
            Watch Full Video
          </Button>
        )}
        {sound && (
          <Button
            onClick={() => setIsMuted(!isMuted)}
            timelineBelow={timelineBelow}
          >
            {isMuted ? 'Activate sound' : 'Deactivate sound'}
          </Button>
        )}
        <ProgressWrapper timelineBelow={timelineBelow}>
          <ProgressBar
            ref={progressBar}
            data-max="0"
            trans={trans}
            timelineBelow={timelineBelow}
          />
        </ProgressWrapper>
      </TextWrapper>
    </Wrapper>
  )
}

export default Video
