import styled from 'styled-components'

const Heading = styled.h2`
  font-size: ${({ size }) => {
    switch (size) {
      case 1:
        return '40px'
      case 2:
        return '26px'
      case 3:
        return '23px'
      case 4:
        return '24px'
      default:
        return '23px'
    }
  }};

  letter-spacing: ${({ size }) => {
    switch (size) {
      case 1:
        return '3.24px'
      case 2:
        return '2px'
      case 3:
        return '1.46px'
      case 4:
        return '1.94px'
      default:
        return '1.46px'
    }
  }};

  line-height: ${({ size }) => {
    switch (size) {
      case 1:
        return '40px'
      case 2:
        return '30px'
      case 3:
        return '24px'
      case 4:
        return '28px'
      default:
        return '24px'
    }
  }};
  
  text-transform: uppercase;

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => {
      switch (size) {
        case 1:
          return '46px'
        case 2:
          return '24px'
        case 3:
          return '23px'
        case 4:
          return '26px'
        default:
          return '23px'
      }
    }};

    letter-spacing: ${({ size }) => {
      switch (size) {
        case 1:
          return '3.72px'
        case 2:
          return '2px'
        case 3:
          return '1.86px'
        case 4:
          return '2px'
        default:
          return '1.86px'
      }
    }};

    line-height: ${({ size }) => {
      switch (size) {
        case 1:
          return '46px'
        case 2:
          return '30px'
        case 3:
          return '30px'
        case 2:
          return '30px'
        default:
          return '30px'
      }
    }};
  }

  @media only screen and (min-width: 1100px) {
    font-size: ${({ size }) => {
      switch (size) {
        case 1:
          return '63px'
        case 2:
          return '30px'
        case 3:
          return '23px'
        case 4:
          return '26px'
        default:
          return '23px'
      }
    }};

    letter-spacing: ${({ size }) => {
      switch (size) {
        case 1:
          return '5px'
        case 2:
          return '2px'
        case 3:
          return '1.86px'
        case 4:
          return '2px'
        default:
          return '1.86px'
      }
    }};

    line-height: ${({ size }) => {
      switch (size) {
        case 1:
          return '76px'
        case 2:
          return '30px'
        case 3:
          return '30px'
        case 2:
          return '30px'
        default:
          return '30px'
      }
    }};
  }
`

export default Heading
