import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import ContextConsumer from '../../helpers/context'
import GraphImg from 'graphcms-image'

import Video from './Video'
import Overlay from './VideoOverlay'

const Wrapper = styled.div`
  width: 100%;
  overflow: ${({ timelineBelow }) => (timelineBelow ? 'visible' : 'hidden')};
  position: relative;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    height: ${({ fullHeight }) => (fullHeight ? '100vh' : 'auto')};
    overflow: hidden;
  }

  .graphcms-image-outer-wrapper {
    width: ${({ fixedRatio }) => (fixedRatio ? '100%' : undefined)};
    height: ${({ fixedRatio }) =>
      fixedRatio ? 'calc((100vw - 30px) / 1.77)' : undefined};
    overflow: ${({ fixedRatio }) => (fixedRatio ? 'hidden' : undefined)};

    @media only screen and (min-width: 768px) {
      height: ${({ fixedRatio }) =>
        fixedRatio ? 'calc((100vw - 45px) / 1.77)' : undefined};
    }

    @media only screen and (min-width: 1024px) {
      height: ${({ fixedRatio }) =>
        fixedRatio ? 'calc((50vw - 60px) / 1.77)' : undefined};
    }

    & > div {
      height: ${({ fixedRatio }) =>
        fixedRatio ? '100% !important' : undefined};
    }
  }
`
const Button = styled.button`
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    color: ${({ theme }) => theme.white};
    font-size: 12px;
    padding: 18px;
  }
`

const ImgVideo = ({
  loop,
  full,
  image,
  fullHeight,
  sound,
  fixedRatio,
  narrow,
  timelineBelow,
}) => {
  const [showVideo, setShowVideo] = useState(false)

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Wrapper
          fullHeight={fullHeight}
          fixedRatio={fixedRatio}
          timelineBelow={timelineBelow}
        >
          {image && (!loop || loop.length < 1) && (
            <Fragment>
              <GraphImg
                image={image}
                backgroundColor={'white'}
                blurryPlaceholder={false}
              />
              {full && full.length > 0 && (
                <Button
                  onClick={e => {
                    setShowVideo(true)
                    set({ videoOpen: true })
                    e.target.nextSibling
                      .querySelector('.popup-video-play-pause')
                      .click()
                  }}
                >
                  Watch Video
                </Button>
              )}
            </Fragment>
          )}

          {loop && loop.length > 0 && (
            <Video
              src={loop}
              button={full && full.length > 0}
              setShowVideo={setShowVideo}
              setContext={set}
              fullHeight={fullHeight}
              sound={sound}
              narrow={narrow}
              timelineBelow={timelineBelow}
            />
          )}

          {full && full.length > 0 && (
            <Overlay
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              src={full}
              setContext={set}
            />
          )}
        </Wrapper>
      )}
    </ContextConsumer>
  )
}

export default ImgVideo
