import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import Follow from './Follow'

const Wrapper = styled.div`
  position: fixed;
  transform: translateZ(0);
  top: 0;
  left: 0;
  z-index: ${({ showVideo }) => (showVideo ? '99999' : '-3')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.black};
  opacity: ${({ showVideo }) => (showVideo ? '1' : '0')};
  pointer-events: ${({ showVideo }) => (showVideo ? 'initial' : 'none')};
  transition: opacity 0.15s ease-in-out;
`

const VideoWrapper = styled.div`
  position: relative;
  width: ${({ isFullscreen }) => (isFullscreen ? '100%' : 'initial')};
  height: ${({ isFullscreen }) => (isFullscreen ? '100%' : 'initial')};
`

const Video = styled.video`
  display: block;
  max-width: ${({ isFullscreen }) =>
    isFullscreen ? 'initial' : 'calc(100vw - 300px)'};
  max-height: ${({ isFullscreen }) =>
    isFullscreen ? 'initial' : 'calc(100vh - 200px)'};

  width: ${({ isFullscreen }) => (isFullscreen ? '100%' : 'auto')};
  height: ${({ isFullscreen }) => (isFullscreen ? '100%' : 'auto')};
  background: url(../../ui/soundon.svg) center no-repeat;

  @media only screen and (max-width: 768px) {
    max-width: ${({ isFullscreen }) =>
      isFullscreen ? 'initial' : 'calc(100vw - 60px)'};
    max-height: ${({ isFullscreen }) =>
      isFullscreen ? 'initial' : 'calc(100vh - 80px)'};
  }

  @media only screen and (max-width: 500px) {
    max-width: ${({ isFullscreen }) =>
      isFullscreen ? 'initial' : 'calc(100vw - 30px)'};
    max-height: ${({ isFullscreen }) =>
      isFullscreen ? 'initial' : 'calc(100vh - 80px)'};
  }

  video[poster] {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const Controls = styled.div`
  display: ${({ isTouch }) => (isTouch ? 'none' : 'block')};
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 37px;
  z-index: 3;
  padding: 0 40px;
  opacity: ${({ controlsVisible }) => (controlsVisible ? '1' : '0')};
  transition: opacity 0.2s ease-in-out;
`

const ControlsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const TimePlayed = styled.div`
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.white};
`

const Buttons = styled.div`
  display: flex;
`

const Mute = styled.button`
  display: block;
  margin: 0;
  border: 0;
  padding: 10px;
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`

const FullScreen = styled.button`
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`

const PlayPause = styled.button`
  display: ${({ isTouch }) => (isTouch ? 'none' : 'block')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-indent: -9999px;
  border: 0;
  padding: 0;
  margin: 0;
  transition: opacity 0.2s ease-in-out;
  /* opacity: ${({ controlsVisible }) => (controlsVisible ? '1' : '0')}; */
  /* background: transparent
    url(../../ui/${({ isPlaying }) => (isPlaying ? 'pause' : 'play')}.svg)
    center no-repeat; */

  &:focus {
    outline: none;
  }
`

const ProgressWrapper = styled.div`
  height: 2px;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
`

const ProgressBar = styled.div`
  display: block;
  width: 0%;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`

const CloseVideo = styled.button`
  position: absolute;
  top: 10px;
  right: 5px;
  display: ${({ isFullscreen }) => (isFullscreen ? 'none' : 'block')};
  padding: 10px;
  border: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    top: 30px;
    right: 30px;
  }

  &:focus {
    outline: none;
  }
`

const VideoOverlay = ({ showVideo, setShowVideo, src, setContext }) => {
  const mainWrapper = useRef(null)
  const vid = useRef(null)
  const progressBar = useRef(null)

  let timerInterval = useRef(null)
  let controlsInterval = useRef(null)

  const [timePlayed, setTimePlayed] = useState('00:00')
  const [controlsVisible, setControlsVisible] = useState(false)
  const [areControlsVisible, setAreControlsVisible] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isTouch, setIsTouch] = useState(false)
  const [playPosX, setPlayPosX] = useState(0)
  const [playPosY, setPlayPosY] = useState(0)

  const exitFullScreenMobile = () => {
    if (!isFullscreen) {
      const mq =
        typeof window !== 'undefined'
          ? window.matchMedia(
              'only screen and (min-width: 813px) and (orientation: landscape), only screen and (min-width: 1024px)'
            )
          : false
      const isDesktop = mq ? mq.matches : false
      if (!isDesktop) {
        closeVideo()
      }
    }
  }

  const closeVideo = () => {
    setShowVideo(false)
    setIsPlaying(true)
    vid.current.pause()
    vid.current.currentTime = 0
    setContext({ videoOpen: false })
    pauseVideo()
  }

  const playVideo = () => {
    vid.current.play()

    timerInterval.current = setInterval(timer, 1000)

    setIsPlaying(true)
  }

  const pauseVideo = () => {
    vid.current.pause()

    clearInterval(timerInterval.current)

    setIsPlaying(false)
  }

  const playPause = setting => {
    if (vid.current.paused || vid.current.ended) {
      playVideo()
    } else {
      pauseVideo()
    }
  }

  const formattedTime = time => {
    const minutes = Math.floor(time / 60)

    let seconds = time % 60

    if (seconds < 10) {
      seconds = `0${seconds}`
    }

    // The output in MM:SS format
    return `0${minutes || '0'}:${seconds || '00'}`
  }

  const timer = () => {
    setTimePlayed(formattedTime(Math.round(vid.current.currentTime)))
  }

  const handleMute = () => {
    vid.current.muted = !vid.current.muted
    setIsMuted(!isMuted)
  }

  // handling fullscreen state

  const handleFullscreen = () => {
    if (isFullScreen()) {
      if (document.exitFullscreen) document.exitFullscreen()
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
      else if (document.webkitCancelFullScreen)
        document.webkitCancelFullScreen()
      else if (document.msExitFullscreen) document.msExitFullscreen()
      setIsFullscreen(false)
    } else {
      if (mainWrapper.current.requestFullscreen)
        mainWrapper.current.requestFullscreen()
      else if (mainWrapper.current.mozRequestFullScreen)
        mainWrapper.current.mozRequestFullScreen()
      else if (mainWrapper.current.webkitRequestFullScreen)
        mainWrapper.current.webkitRequestFullScreen()
      else if (mainWrapper.current.msRequestFullscreen)
        mainWrapper.current.msRequestFullscreen()
      setIsFullscreen(true)
    }
  }

  const isFullScreen = () => {
    return !!(
      document.fullScreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    )
  }

  const setDurationVal = () => {
    progressBar.current.setAttribute(
      'data-max',
      Math.round(vid.current.duration)
    )
  }

  const handleSkipAhead = event => {
    const pos =
      (event.pageX -
        (progressBar.current.parentElement.offsetLeft +
          vid.current.parentElement.offsetLeft)) /
      progressBar.current.parentElement.offsetWidth
    vid.current.currentTime = pos * vid.current.duration

    timer()
  }

  const handleTime = () => {
    if (!progressBar.current.getAttribute('data-max'))
      progressBar.current.setAttribute('data-max', vid.current.duration)

    progressBar.current.style.width =
      Math.round((vid.current.currentTime / vid.current.duration) * 100) + '%'

    if (vid.current.ended) {
      vid.current.currentTime = 0

      setIsPlaying(false)
    }
  }

  const showControls = () => {
    if (!isTouch) setControlsVisible(true)
  }

  const hideControls = () => {
    if (!isTouch) {
      setControlsVisible(false)

      clearTimeout(controlsInterval.current)
    }
  }

  const handleMouseMove = e => {
    setPlayPosX(e.clientX)
    setPlayPosY(e.clientY)
  }

  const watchControls = e => {
    handleMouseMove(e)

    if (!isTouch) {
      clearTimeout(controlsInterval.current)

      if (areControlsVisible) {
        setControlsVisible(true)
        setAreControlsVisible(false)
      }

      controlsInterval.current = setTimeout(() => {
        setControlsVisible(false)
        setAreControlsVisible(true)
      }, 3000)
    }
  }

  useEffect(() => {
    if ('ontouchstart' in window || navigator.msMaxTouchPoints > 0) {
      setIsTouch(true)
    } else {
      setIsTouch(false)
    }

    vid.current.addEventListener('webkitendfullscreen', function () {
      setIsFullscreen(false)
      exitFullScreenMobile()
    })

    return () => {
      vid.current.removeEventListener('webkitendfullscreen', function () {
        setIsFullscreen(false)
        exitFullScreenMobile()
      })
    }
  }, [vid])

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        if (!isFullscreen) {
          closeVideo()
        }
      }
    }

    document.addEventListener('keyup', handleEsc)
    document.addEventListener('fullscreenchange', function (e) {
      setIsFullscreen(!!(document.fullScreen || document.fullscreenElement))
    })
    document.addEventListener('webkitfullscreenchange', function () {
      setIsFullscreen(!!document.webkitIsFullScreen)
    })
    document.addEventListener('mozfullscreenchange', function () {
      setIsFullscreen(!!document.mozFullScreen)
    })
    document.addEventListener('msfullscreenchange', function () {
      setIsFullscreen(!!document.msFullscreenElement)
    })
    return () => {
      document.removeEventListener('fullscreenchange', function (e) {
        setIsFullscreen(!!(document.fullScreen || document.fullscreenElement))
      })
      document.removeEventListener('webkitfullscreenchange', function () {
        setIsFullscreen(!!document.webkitIsFullScreen)
      })
      document.removeEventListener('mozfullscreenchange', function () {
        setIsFullscreen(!!document.mozFullScreen)
      })
      document.removeEventListener('msfullscreenchange', function () {
        setIsFullscreen(!!document.msFullscreenElement)
      })
      document.removeEventListener('keyup', handleEsc)
    }
  }, [])

  useEffect(() => {
    exitFullScreenMobile()
  }, [isFullscreen])

  return (
    <Wrapper
      ref={mainWrapper}
      isFullscreen={isFullscreen}
      showVideo={showVideo}
      onMouseEnter={showControls}
      onMouseLeave={hideControls}
      onMouseMove={watchControls}
    >
      <CloseVideo onClick={closeVideo} isFullscreen={isFullscreen}>
        Close
      </CloseVideo>

      <VideoWrapper isFullscreen={isFullscreen}>
        <Video
          ref={vid}
          preload="auto"
          controls={isTouch}
          onTimeUpdate={handleTime}
          onLoadedMetadata={setDurationVal}
          isFullscreen={isFullscreen}
        >
          {src && typeof src === 'string' ? (
            <source src={src} />
          ) : (
            src.map((item, i) => {
              return <source src={item.url} type={item.mimeType} key={i} />
            })
          )}
        </Video>

        <PlayPause
          isTouch={isTouch}
          controlsVisible={controlsVisible}
          isPlaying={isPlaying}
          onClick={playPause}
          type="button"
          className={'popup-video-play-pause'}
        >
          Play/Pause
        </PlayPause>
      </VideoWrapper>
      <Controls isTouch={isTouch} controlsVisible={controlsVisible}>
        <ControlsTop>
          <TimePlayed>{timePlayed}</TimePlayed>
          <Buttons>
            <Mute onClick={handleMute} isMuted={isMuted} type="button">
              Sound {isMuted ? 'off' : 'on'}
            </Mute>
            <FullScreen
              onClick={handleFullscreen}
              isFullscreen={isFullscreen}
              type="button"
            >
              {isFullscreen ? 'Close Fullscreen' : 'Fullscreen'}
            </FullScreen>
          </Buttons>
        </ControlsTop>
        <ProgressWrapper onClick={handleSkipAhead}>
          <ProgressBar ref={progressBar} data-max="0"></ProgressBar>
        </ProgressWrapper>
      </Controls>
      <Follow
        posX={playPosX}
        playPosY={playPosY}
        isPlaying={isPlaying}
        show={controlsVisible}
      />
    </Wrapper>
  )
}

export default VideoOverlay
